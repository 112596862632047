import type { PrefetchableQueryHookResult, PrefetchedQueryState } from '@change/core/react/async';
import { prefetchQuery, usePrefetchableQuery } from '@change/core/react/async';
import type { UtilityContext } from '@change/core/react/utilityContext';
import { useUtilityContext } from '@change/core/react/utilityContext';

import type { PetitionInfo, PetitionInfoErrorReason } from 'src/app/pages/petition/shared/types';

import type { PartialPetition } from '../../api/petition';
import { getPetitionInfo } from '../../api/petition';

import { isCommentsEnabled } from './isCommentsEnabled';

type StateData = { slug: string; info: PetitionInfo };

async function getPetitionInfoOrThrow(slug: string, utilityContext: UtilityContext): Promise<PetitionInfo> {
	let petition: PartialPetition | undefined;
	try {
		petition = await getPetitionInfo(slug, utilityContext);
	} catch (e) {
		throw new Error('other' as PetitionInfoErrorReason, { cause: e });
	}
	if (!petition) throw new Error('notFound' as PetitionInfoErrorReason);
	return {
		id: petition.id,
		ask: petition.ask,
		hideComments: !isCommentsEnabled(petition),
		emergencyBanner: petition.emergencyBannerContent || undefined,
	};
}

export type PetitionInfoState = PrefetchedQueryState<
	StateData,
	Readonly<{ reason: PetitionInfoErrorReason; cause?: unknown }>
>;

export function usePetitionInfo(
	slug: string,
	prefetchedState?: PetitionInfoState,
): PrefetchableQueryHookResult<StateData, Readonly<{ reason: PetitionInfoErrorReason; cause?: unknown }>> {
	const utilityContext = useUtilityContext();

	return usePrefetchableQuery(
		async () => ({ slug, info: await getPetitionInfoOrThrow(slug, utilityContext) }),
		[slug, utilityContext],
		{
			id: slug,
			prefetchedState,
			errorHandler: (e: Error) =>
				({ reason: e.message, cause: e.cause }) as {
					reason: PetitionInfoErrorReason;
					cause?: unknown;
				},
		},
	);
}

export const prefetchPetitionInfo = async (slug: string, utilityContext: UtilityContext): Promise<PetitionInfoState> =>
	prefetchQuery(async () => ({ slug, info: await getPetitionInfoOrThrow(slug, utilityContext) }), {
		id: slug,
		errorHandler: (e: Error) => ({ reason: e.message as PetitionInfoErrorReason, cause: e.cause }),
	});
