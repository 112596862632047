import type { UtilityContext } from '@change/core/react/utilityContext';
import type { Session } from '@change/core/session';

import type { UserInfo } from 'src/app/pages/petition/shared/types';
import { getSignature } from 'src/app/shared/api/signature';
import { FCM_NEW_PETITION_PAGE_ACCESS_EXPERIMENT } from 'src/app/shared/fcm/configs';

import { getUserPetitionInfo } from './userPetitionInfo';

export async function getUserInfo(
	session: Session | undefined,
	slug: string,
	utilityContext: UtilityContext,
): Promise<UserInfo> {
	if (!session) {
		return {
			ownPetition: false,
			canEdit: false,
			signed: false,
			newPetitionPage: false,
		};
	}

	const userId = session.user?.id;
	if (!userId) {
		return {
			ownPetition: false,
			canEdit: false,
			signed: false,
			newPetitionPage: await getNewPetitionPage(utilityContext),
		};
	}

	const [signature, petitionInfo, isInNewPetitionPageVariant] = await Promise.all([
		getSignature(userId, slug, utilityContext),
		getUserPetitionInfo(slug, utilityContext),
		getNewPetitionPage(utilityContext),
	]);

	const ownPetition = petitionInfo?.user?.id === userId;
	const canEdit = petitionInfo?.viewerCanEdit;

	// Starters and editors need to be able to see the "Dashboard" tab from the old page.
	const newPetitionPage = isInNewPetitionPageVariant && !ownPetition && !canEdit;

	return {
		ownPetition,
		canEdit,
		newPetitionPage,
		signed: !!signature,
	};
}

const getNewPetitionPage = async (utilityContext: UtilityContext): Promise<boolean> => {
	const fcm = await utilityContext.fcm.get({
		newPetitionPageAccess: FCM_NEW_PETITION_PAGE_ACCESS_EXPERIMENT,
	});
	if (!fcm.newPetitionPageAccess?.enabled) {
		return false;
	}

	const experiment = utilityContext.experiments.get(fcm.newPetitionPageAccess?.experimentName);
	await experiment.treat();

	return experiment.variation === 'gamma';
};
