import type { TrackingFn } from '@change/core/react/tracking';

import type { TrackingTabName } from 'src/app/pages/petition/shared/tabs';

type Context = {
	tab: TrackingTabName;
	currentPage?: string;
	ownPetition?: boolean;
};

export async function trackTabClick(
	petitionId: string | number,
	{ currentPage, tab, ownPetition }: Context,
	track: TrackingFn,
): Promise<boolean> {
	return track<{
		petition_id: string | number;
		from_page?: string;
		context: TrackingTabName;
		own_petition: boolean;
	}>('petition_tab_nav_click', {
		petition_id: petitionId,
		from_page: currentPage,
		context: tab,
		own_petition: !!ownPetition,
	});
}
