import { useMemo } from 'react';
import type { JSX } from 'react';

import { useI18n } from '@change/core/react/i18n';
import { Separator } from '@change/design-system/components/content';
import { Box, Container } from '@change/design-system/layout';

import type { PetitionInfo, Tab, UserInfo } from 'src/app/pages/petition/shared/types';

import { useCurrentRoute } from '../hooks/currentRoute';
import { useTabs } from '../hooks/tabs';

import { TabContainer } from './Tab';

type Props = Readonly<{
	slug: string;
	activeTab?: Tab;
	petitionInfo: PetitionInfo;
	userInfo: UserInfo;
}>;

export function PetitionTabsContainer({ slug, activeTab, petitionInfo, userInfo }: Props): JSX.Element | null {
	const { translate } = useI18n();
	const tabsContext = useMemo(() => ({ petitionInfo, userInfo }), [petitionInfo, userInfo]);

	const tabs = useTabs(tabsContext);

	const route = useCurrentRoute();

	const commonTabProps = {
		petitionId: petitionInfo.id,
		slug,
		clickTrackingData: { ownPetition: userInfo.ownPetition, ...route?.trackingData },
	};

	return (
		<Box
			sx={{
				whiteSpace: 'nowrap',
				overflowX: 'auto',
				overflowY: 'hidden',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&::-webkit-scrollbar': {
					display: 'none',
				},
			}}
			aria-label={translate('fe.components.petition_nav_header.label')}
		>
			<Container variant="small">
				{tabs.map((tab) => (
					<TabContainer key={tab.tab} config={tab} active={activeTab === tab.tab} {...commonTabProps} />
				))}
			</Container>
			<Separator m={0} sx={{ borderBottomWidth: 2, position: 'unset' }} />
		</Box>
	);
}
