import { matchPath } from 'react-router';

import { getRoutes } from './getRoutes';
import type { SubRoute } from './routes';

export function getMatchingRoute(pathname: string): SubRoute | undefined {
	return getRoutes().find(
		({ path, feProxyPath }) => matchPath(path, pathname) || (!!feProxyPath && matchPath(feProxyPath, pathname)),
	);
}
