import { APP_ROUTES } from 'src/app/routes';

import type { SubRoute, SubRouteConfig } from './routes';

export function getRoutes(): readonly SubRoute[] {
	return APP_ROUTES.filter(
		(route) => !!route.additionalConfig && (route.additionalConfig as SubRouteConfig).routeType === 'petitionSubRoute',
	).map((route) => ({
		...(route.additionalConfig as SubRouteConfig),
		id: route.id,
		path: Array.isArray(route.path) ? route.path[0] : route.path,
		feProxyPath: Array.isArray(route.feProxyPath) ? route.feProxyPath[0] : route.feProxyPath,
	}));
}
