import type { JSX } from 'react';

import { withPrefetchedData } from '@change/core/react/prefetch';
import type { PrefetchDependency, PrefetchedUserDataProps } from '@change/core/react/prefetch';
import { Redirect, useKnownAvailableParams } from '@change/core/react/router';
import { Box } from '@change/design-system/layout';

import { getMatchingRoute } from 'src/app/pages/petition/shared/routes';
import type { UserInfo } from 'src/app/pages/petition/shared/types';
import { PetitionGammaHome } from 'src/app/pages/petitionGamma';
import { isLoaded } from 'src/app/shared/utils/async';

import { useCurrentRoute } from '../hooks/currentRoute';
import { prefetchUserInfo, useUserInfo } from '../hooks/userInfo';

import { PetitionHeaderContainer } from './Header';

type PrefetchedUserData = UserInfo;

export const PetitionHomeContainer = withPrefetchedData(
	({ prefetchedUserData }: PrefetchedUserDataProps<PrefetchedUserData>): JSX.Element | null => {
		const { slug } = useKnownAvailableParams<{ slug: string }>();

		const userInfoState = useUserInfo(slug, prefetchedUserData);

		const currentRoute = useCurrentRoute();

		if (!currentRoute) {
			return <Redirect to={`/p/${encodeURIComponent(slug)}`} />;
		}

		// Serve the new details page to users in test group. Note: the old header and loader (below)
		// may be displayed if userInfo needs loading. This should only occur if the data was not
		// prefetched (uncommon).
		if (currentRoute.id === 'petition-details' && isLoaded(userInfoState) && userInfoState.info.newPetitionPage) {
			return <PetitionGammaHome />;
		}

		const { component: TabBody } = currentRoute;

		return (
			<>
				<PetitionHeaderContainer slug={slug} userInfoState={userInfoState} />
				<Box as="main" data-maincontent>
					<TabBody userInfoState={userInfoState} />
				</Box>
			</>
		);
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: async ({ path, getUserData }) => {
			const oldCommonDeps: readonly PrefetchDependency[] = [PetitionHeaderContainer];
			const route = getMatchingRoute(path);
			if (!route) {
				return oldCommonDeps;
			}

			// The new page only appears on the details route.
			if (route.id !== 'petition-details') {
				return [...oldCommonDeps, route.component];
			}

			// We have to wait for user data to decide which version of the page to serve.
			const userData = (await getUserData()) as UserInfo;
			if (!userData.newPetitionPage) {
				return [...oldCommonDeps, route.component];
			}

			// Only prefetch the new page's deps.
			return [PetitionGammaHome];
		},
		prefetchUserData: async (context) => {
			const {
				params: { slug },
				utilityContext,
				session,
			} = context;
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const userInfo = await prefetchUserInfo(session, slug!, utilityContext);
			return userInfo || { canEdit: false, ownPetition: false, signed: false, newPetitionPage: false };
		},
	},
);
