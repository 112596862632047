import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

import { Translate } from '@change/core/react/i18n';
import { useTracking } from '@change/core/react/tracking';

import type { TabConfig } from 'src/app/pages/petition/shared/tabs';

import { trackTabClick } from '../api/track';
import { Tab } from '../components/Tab';

type Props = Readonly<{
	slug: string;
	petitionId: string | number;
	config: TabConfig;
	clickTrackingData: Readonly<{
		currentPage?: string;
		ownPetition?: boolean;
	}>;
	active?: boolean;
}>;

export function TabContainer({
	slug,
	petitionId,
	config,
	clickTrackingData,
	active,
}: PropsWithChildren<Props>): React.JSX.Element {
	const track = useTracking();

	const handleClick = useCallback(() => {
		void trackTabClick(petitionId, { ...config.clickTrackingData, ...clickTrackingData }, track);
	}, [petitionId, config, clickTrackingData, track]);

	const href = useMemo(() => config.buildUrl(slug), [config, slug]);

	return (
		<Tab
			onClick={handleClick}
			href={href}
			active={active}
			aria-current={active ? 'page' : undefined}
			data-qa={`${config.tab}-header-link`}
		>
			<Translate value={config.name} />
		</Tab>
	);
}
